import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ModuleArea from '../components/ModularContent/ModuleArea';
import PageHeading from '../components/Layout/PageHeading/PageHeading';

const OtherPagesTemplate = ({
  data: {
    datoCmsOtherPage: {
      title,
      modularContent,
      id,
      metaTags,
      pageHeading,
      pageSubheading,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper
      breadcrumbs={breadcrumbs}
      pageData={pageContext}
      metaTags={metaTags}
    >
      <PageHeading heading={pageHeading} subHeading={pageSubheading} />
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default OtherPagesTemplate;

export const query = graphql`
  query OtherPagesQuery($locale: String!, $id: String!) {
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    datoCmsOtherPage(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      pageHeading
      pageSubheading
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      modularContent {
        ... on DatoCmsDetailedInfoBlock {
          ...DetailedInfoBlock
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsTestimonialsBlock {
          ...TestimonialsBlock
        }
        ... on DatoCmsGalleryBlock {
          ...GalleryBlock
        }
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsButtonBlock {
          ...ButtonBlock
        }
        ... on DatoCmsCircleImagesBlock {
          ...CircleImagesBlock
        }
        ... on DatoCmsCollectionsListBlock {
          ...CollectionsListBlock
        }
        ... on DatoCmsCardGridBlock {
          ...CardGridBlock
        }
      }
    }
  }
`;
